<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="2"></pageBanner>
      <pageNavLine :infoList="navInfoList" :leftList="aboutNavList" leftLabelKey="title" :leftCurrent="curActive"
        @navClick="clickNavItem"></pageNavLine>
      <div class="common-clumb-min commonweb clearfix">
        <div class="clumb-title">
          <h3 class="cn font22">{{ titleList[curActive].label }}</h3>
          <!-- <h2 class="en font24">{{ titleList[curActive].subTitle }}</h2> -->
        </div>
      </div>
      <!-- 1 公司简介 -->
      <template v-if="aboutNavList[curActive] && aboutNavList[curActive].aboutCategory == 1 && aboutInfo[0]">
        <div class="Pledge-min commonweb padtb">
          <div class="Pledetext html-area aboutinfo-area" v-html="aboutInfo[0].content">
          </div>
        </div>
      </template>
      <!-- 2 企业文化 -->
      <template v-if="aboutNavList[curActive] && aboutNavList[curActive].aboutCategory == 2 && aboutInfo[0]&&false">
        <div class="Pledge-min commonweb padtb img-content">
          <img :src="$BaseUrl + aboutInfo[0].imageUrl" class="img-area" />
        </div>
      </template>
      <!-- 3 资质荣誉 -->
      <template v-if="aboutNavList[curActive] && aboutNavList[curActive].aboutCategory == 3">
        <div class="Honors-min commonweb padtb">
          <div class="Honor-list">
            <ul class="clearfix imgae-same-size">
              <li class="col-sm-4" v-for="(item, index) in aboutInfo" :key="index">
                <a href="javascript:void(0)">
                  <div class="elc-img"> <img :src="$BaseUrl + item.imageUrl" alt="荣誉证书" /> </div>
                  <p class="elc-text">{{ item.imageDescription }}</p>
                </a>
              </li>
            </ul>
          </div>
          <pagination :current="currentPage" :pages="pages" @pageChange="clickPage"></pagination>
        </div>
      </template>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getAboutUs, getAboutUsDetail } from "@/api/aboutUs"
import { getDicts } from "@/api/common"
import pagination from "@/components/pagination"
export default {
  components: { pagination },
  data() {
    return {
      aboutObject: {
      },
      aboutNavList: [],
      curActive: 0,
      aboutInfo: [],
      pages: 1,
      currentPage: 1,
      navInfoList: [{
        name: '关于华博'
      }],
      titleList: {
        0: { label: '公司简介', subTitle: 'COMPANY PROFILE' },
        // 1: { label: '企业文化', subTitle: 'COMPANY CULTURE' },
        1: { label: '荣誉资质', subTitle: 'COMPANY HONORS' }
      }
    }
  },
  async mounted() {
    let res = await getDicts('about_us_type')
    res.forEach(v => {
      this.$set(this.aboutObject, Number(v.dictValue), v.dictLabel)
    })
    this.getAboutUs()
  },
  methods: {
    getAboutUs() {
      getAboutUs().then(res => {
        let temp= []
        res.forEach(v => {
          v.title = this.aboutObject[Number(v.aboutCategory)]
          if(temp.title!='企业文化'&&v.aboutId!=2){
            temp.push(v)
          }
        })
        this.aboutNavList =temp
        
        if (this.$route.query.id != undefined) {
          let idx = this.aboutNavList.findIndex(t => { return t.aboutId == this.$route.query.id })
          this.curActive = idx == -1 ? 0 : idx
        }
        this.clickNavItem({ item: this.aboutNavList[this.curActive], index: this.curActive, })
      })
    },
    clickNavItem({ item, index }) {
      this.curActive = index
      let params = {}
      if (this.curActive == 2) {
        this.currentPage = 1
        params.pageNum = 1
        params.pageSize = 9
      }
      this.aboutInfo=[]
      getAboutUsDetail(item.aboutCategory, params).then(res => {
        this.aboutInfo = res.rows
        console.log(res.rows)
        if (this.curActive == 2) {
          this.pages = Math.ceil(res.total / 9)
        }
      })
    },
    clickPage(page) {
      this.currentPage = page
      getAboutUsDetail(3, { pageNum: this.currentPage, pageSize: 9 }).then(res => {
        this.aboutInfo = res.rows
        this.pages = Math.ceil(res.total / 9)
      })
    },
    downFile(item) {
      // download()
      //   download(item.imageUrl, {},`华博教育.png`)
      let image = new Image();
      // 解决跨域 canvas污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.$BaseUrl + item.imageUrl
      //   + '?time=' + new Date().valueOf() // 加时间戳
      this.$nextTick(() => {
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.download = '华博.png'
            a.href = url
            a.click()
            a.remove()
            URL.revokeObjectURL(url)
          })
        }
      })
    }

  }
}
</script>
<style scoped>
.html-area {
  white-space: pre-wrap;
}

.img-content {
  display: flex;
  justify-content: center;
}

.img-box {
  display: flex;
  justify-content: center;
}

.elc-img {
  width: 380px;
  height: 240px;
  overflow: hidden;
  display: flex;
  align-items: center;

}

.elc-img img {
  max-width: 380px;
  max-height: 240px;
}
.aboutinfo-area{
  font-size: 16px;
}
</style>